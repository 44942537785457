.olapic-carousel-list-container {
  .olapic-carousel-partial-container {
    height: 136px;
    line-height: 136px;
    background: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0;
  }
}

.vto-skin-care-screens {
  &__landing {
    &-cta {
      margin-bottom: 20px;
    }
  }
}
