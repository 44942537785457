.checkout {
  .social-login {
    &__divider {
      width: 100%;
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  .divider {
    display: none;
  }
}

.social-login {
  &__email-opt-in {
    .email-optin {
      text-transform: none;
      font-size: 12px;
      margin-top: 13px;
      letter-spacing: 0.05em;
      text-align: $ldirection;
    }
  }
  &__terms {
    text-align: $ldirection;
    padding: 0;
    p {
      text-transform: none;
      font-size: 12px;
    }
  }
  label {
    text-transform: none;
    margin-top: 10px;
    text-align: $ldirection;
  }
}

.device-mobile {
  .sign-in-component {
    .social-login {
      padding: 20px;
      &__divider {
        width: 100%;
      }
      &__terms {
        p {
          line-height: 18px;
        }
      }
    }
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    @include swap_direction(margin, 0 100px);
    margin: 0;
    .social-login {
      &__container {
        text-align: center;
      }
      &__divider {
        width: 100%;
      }
      &__terms {
        font-size: 12px;
      }
    }
    .email-optin {
      text-transform: none;
      letter-spacing: 0.05em;
      margin-top: 20px;
    }
  }
}
/* Order confirmation page */
#confirm {
  .pg_wrapper {
    .social-login {
      padding: 0;
      &__container {
        text-align: $ldirection;
        padding-bottom: 0;
        margin-bottom: 20px;
        width: auto;
      }
      &__terms,
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        font-size: 40px;
        font-family: $optimalight;
        text-transform: capitalize;
        letter-spacing: 0;
      }
      &__info {
        margin-bottom: 10px;
        display: block;
      }
      .email-optin {
        text-transform: none;
        padding-top: 0;
        font-size: 15px;
        letter-spacing: 0.05em;
      }
    }
  }
}
/* Profile preferences page */
.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.device-pc {
  .social-login {
    &__container {
      margin: 0 auto;
      width: 88%;
    }
    &__divider {
      width: 100%;
    }
  }
  .pg_wrapper.account-page.sidebar-page {
    margin-top: 0;
  }
  .social-info {
    padding-top: 0;
    border-top: none;
    padding-#{$ldirection}: 19px;
    @media #{$medium-only} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$large-up} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$xlarge-up} {
      margin-#{$ldirection}: 20%;
    }
    &__header {
      font-size: 30px;
    }
  }
}

.account-page {
  .sidebar-page {
    &__content {
      padding: 0;
    }
  }
}

.social-info {
  &__content {
    a {
      text-decoration: underline;
    }
  }
}
