#ot-sdk-btn {
  &.ot-sdk-show-settings-btn {
    background: none;
    border: none;
    padding: 0;
    color: $color-navy;
    text-decoration: underline;
    letter-spacing: 0.05em;
  }
  &.ot-sdk-show-settings-btn-en {
    background: none;
    border: none;
    padding: 0;
    color: $color-navy;
    text-decoration: underline;
    letter-spacing: 0.05em;
  }
  &.ot-sdk-show-settings-btn-my {
    background: none;
    border: none;
    padding: 0;
    color: $color-navy;
    text-decoration: underline;
    letter-spacing: 0.05em;
  }
}
