.appt-book {
  .appt-book-page-header-content {
    h2 {
      font-size: 40px;
    }
  }
  #appointment-book-sections {
    .location-select-section {
      .basic-responsive {
        text-align: center;
        .location-select-section-content__title {
          font-size: 40px;
        }
        .location-select-section-content__subhead {
          min-height: 400px;
        }
      }
      .appt-book-location-selector {
        margin-top: 30px;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .service {
        .selection-time {
          font-size: 16px;
        }
      }
      .book-appt-container {
        .mobile-phone-fieldset {
          height: auto;
        }
      }
    }
  }
}
