.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.error_messages {
  color: $color-red;
}

.ui-widget-content {
  height: 12em;
  overflow-y: auto;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.spacer {
  border-bottom: 1px solid $color-light-gray;
}
//commenting till implementation of drupal toolbar on perlgem page completes
body.toolbar-drawer {
  padding-top: 0 !important;
  #toolbar-trigger {
    display: none;
  }
}

footer {
  .trust_mark_footer {
    margin-left: 0px;
    margin-right: 0px;
    border-top: 1px solid #a4a4ab;
    padding-top: 10px;
    padding-bottom: 10px;
    a.trust_mark_footer__link {
      width: 128px;
      height: 145px;
      background-image: url('/media/images/global/lo2-white.png');
      background-repeat: no-repeat;
      display: block;
      margin: auto;
    }
  }
}

.needs_video_link {
  img {
    width: 100%;
  }
}

.order-details-page {
  .order-details--cart {
    .cart-item {
      .cart_order_detail {
        float: left;
        width: 60%;
      }
      .cart_order_total {
        text-align: right;
      }
    }
  }
}

.email-signup {
  input[type='tel'] {
    margin: 15px 0;
  }
}

.new-dimension-page {
  .needs_video_link {
    .video-link img {
      width: 100px;
    }
  }
  .mpp h2.mpp__header {
    margin-top: 0;
  }
}

.device-mobile {
  #colorbox {
    &.welcome15-overlay.alt_layout1 {
      background: $color-white;
      #cboxClose {
        background-image: url('/media/images/pro_active_popup/close.jpg') !important;
        margin-#{$ldirection}: 25px;
      }
      .email_popover__content {
        padding: 30px 0;
        margin-top: 25px;
        .email_input {
          margin-bottom: 35px;
          .form-text {
            box-shadow: unset;
            height: 42px;
            width: 100%;
            margin-bottom: 30px;
          }
          .form-submit {
            width: 100%;
          }
        }
        .email_signup__terms {
          p {
            margin: 0;
            text-transform: initial;
          }
          .label {
            padding: 0;
            &::before {
              display: none;
            }
          }
        }
      }
      .welcome15-overlay {
        &__header {
          font: italic 40px $font-bauerbodoni-roman;
          text-transform: uppercase;
          letter-spacing: normal;
        }
      }
      #cboxContent {
        max-width: unset;
        padding: 0 22px;
      }
      #cboxLoadedContent {
        margin: 0 auto;
        width: auto !important;
      }
      .email_popover {
        .email_popover__content {
          padding-top: 20% !important;
          h4 {
            font-size: 32px;
            line-height: 32px;
          }
          p {
            font-size: 12px;
          }
          .popup-offer__sign-up-form-error-messages {
            font-size: 15px;
            line-height: 20px;
            padding: 5px 0 5px 0;
          }
        }
        .email_input {
          input[type='email'] {
            &.form-text.error {
              border: 1px red solid;
            }
          }
        }
      }
    }
    &.colorbox__wishlist-confirm {
      #cboxClose {
        top: 10px !important;
      }
      #cboxContent {
        height: auto !important;
      }
    }
  }
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='age'] {
          &::before {
            content: 'FILTER:';
            font-size: 12px;
          }
        }
      }
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_checkbox_wrapper {
      input[type='checkbox'] ~ label {
        &::after,
        &::before {
          background: none;
          top: -1px;
          left: -14px;
          border: none;
        }
      }
    }
  }
}
