.write_a_review__container {
  #pr-write {
    .p-w-r {
      .form-group[data-reactid-powerreviews$='ag139831_msq74646'],
      .form-group[data-reactid-powerreviews$='ag140725_msq75273'],
      .pr-elist-form-group {
        display: none;
      }
    }
  }
}
