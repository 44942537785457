@if $password_strengthen {
  #signin {
    .sign-in-component {
      &__password-wrapper {
        &.password-field {
          overflow: visible;
        }
      }
    }
  }
  .registration-page {
    &__content {
      .profile-info {
        &__change_pwd_link {
          margin-top: 8px;
        }
      }
    }
  }
  .password-field {
    &__info {
      #signin & {
        @media #{$medium-up} {
          -webkit-transform: translateY(-27%);
          -moz-transform: translateY(-27%);
          -o-transform: translateY(-27%);
          transform: translateY(-27%);
          top: 0;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: 0;
          -webkit-transform: translateY(3%);
          -moz-transform: translateY(3%);
          -o-transform: translateY(3%);
          transform: translateY(-15%);
        }
      }
      &-checkout {
        @media #{$medium-up} {
          margin-top: 5px;
          #{$ldirection}: 42%;
          position: absolute;
          border: 1px solid $color-black;
          width: 200px;
          -webkit-transform: translateY(-74%);
          -moz-transform: translateY(-74%);
          -o-transform: translateY(-74%);
          transform: translateY(-74%);
        }
        &::before {
          @media #{$medium-up} {
            content: '';
            position: absolute;
            top: 43%;
            #{$rdirection}: 100%;
            border: 5px solid;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
      }
    }
    &__rules {
      font-size: 11px;
      .checkout & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
  #registration {
    .profile-info__change-pwd-link {
      padding-top: 10px;
    }
    &.device-mobile {
      #colorbox {
        div {
          overflow: initial;
        }
        #cboxClose {
          #{$rdirection}: 15px;
          top: 11px;
        }
        &.password-update-overlay {
          height: 580px !important;
          top: 200px !important;
          position: absolute !important;
          .profile-password-update {
            &__fieldset {
              width: 0px;
            }
            #cboxLoadedContent {
              height: auto !important;
            }
          }
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__fieldset {
        .form-item input {
          width: 100%;
        }
      }
      &__button {
        border: 1px solid $color-light-gray;
        border-color: $color-light-gray;
      }
      &__rules {
        padding: 0px;
        li {
          display: flex;
        }
      }
    }
  }
}

#cboxContent {
  height: 450px;
}
